const bhParallax = {
	parallaxItems: "",
	init(){
		bhParallax.parallaxItems = document.querySelectorAll('.parallax')
		bhParallax.scroll()
		bhParallax.scrollTo()
	},
	scroll(){
		const windowHeight = window.innerHeight

		bhParallax.parallaxItems.forEach(item => {
			const SPEED = item.dataset.speed
			const elementTop = item.getBoundingClientRect().top
			const elementHeight = item.getBoundingClientRect().height
			if (elementTop < windowHeight && elementTop > 0 - elementHeight) {
				if (window.innerWidth > 768) {
					item.style.transform = `translateY(${(windowHeight / 2 - elementTop - elementHeight / 2) / (SPEED * 10)}vh)`
				} else {
					item.style.transform = `translateY(0)`
				}
			} else {
			}
		})
	},

	scrollTo() {
		const queryParams = window.location.search.split('&')
		queryParams.map(param => {
			if (param.indexOf('view') > -1) {
				const id = param.split('=')[1]
				document.getElementById(id).scrollIntoView()

			}
		})
	}
}

document.addEventListener('DOMContentLoaded', bhParallax.init())
window.addEventListener('scroll', bhParallax.scroll)