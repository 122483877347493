jQuery(document).ready(function ($) {
	var Tool = {
		"init": function (container) {
			this.setup(container);
		},
		"result": {
			0: {
				h: "100 % Kontaktlinsentyp!",
				t: "Du bist der absolute Kontaktlinsentyp, deine Augen fühlen sich damit rundum wohl und du genießt die Freiheit mit deinen Kontaktlinsen. Brillen sind einfach nicht dein Ding!"
			},
			10: {
				h: "Klare Tendenz zur Kontaktlinse!",
				t: "Für dich sind Kontaktlinsen die richtige Wahl. Du findest sie praktisch, genießt die Freiheit damit und deine Augen fühlen sich wohl. Die Brille nutzt du nur selten als Ersatz.",
			},
			20: {
				h: "Meistens Kontaktlinse, spontan mal die Brille!",
				t: "Die meiste Zeit des Tages genießt du es Kontaktlinsen zu tragen und freust dich über die Freiheit – und auch deine Augen empfinden sie als angenehm. Eine Brille ist aber ab und zu eine gute Alternative.",
			},
			30: {
				h: "Kontaktlinsenträger, der auch Brillen mag!",
				t: "Du trägst am liebsten Kontaktlinsen, doch in einigen Situationen ist eine Brille für dich einfach die bessere Wahl.",
			},
			40: {
				h: "Beides – mit Tendenz zur Linse!",
				t: "Für dich macht es kaum einen Unterschied, ob du Kontaktlinse oder Brille trägst – auch wenn die Kontaktlinse leichter Favorit ist. Beides ist für dich angenehm und du entscheidest dich, was spontan besser zu dir passt.",
			},
			50: {
				h: "Du kannst beides tragen!",
				t: "Für dich haben Brillen und Kontaktlinsen jeweils Ihre Vorteile, du entscheidest situationsbezogen, da du beides gerne trägst.",
			},
			60: {
				h: "Beides, mit Tendenz zur Brille!",
				t: "Für dich macht es kaum einen Unterschied, ob du Brille oder Kontaktlinse trägst, auch wenn die Brille leichter Favorit ist.  Beides ist für dich angenehm und du entscheidest spontan, worauf du Lust hast.",
			},
			70: {
				h: "Brillenträger, der auch Kontaktlinsen mag!",
				t: "Brillen haben für dich leichte Vorteile gegenüber Kontaktlinsen. Du trägst daher gerne deine Brille(n), doch bei manchen Aktivitäten wie beim Sport oder in der Sauna fühlst du dich mit Kontaktlinsen wohler.",
			},
			80: {
				h: "Meistens Brille, spontan mal Kontaktlinsen!",
				t: "Die Brille ist für dich attraktiv, du fühlst dich damit gut und trägst sie gerne. Gelegentlich sollten Kontaktlinsen tragbar sein, bitte in diesem Fall den Anpasser um Rat fragen.",
			},
			90: {
				h: "Klare Tendenz zur Brille!",
				t: "Eine Brille ist für dich praktisch und gleichzeitig ein modisches Accessoire. Kontaktlinsen stehst du kritisch gegenüber, eventuell kann dir ein Anpasser hilfreiche Ratschläge geben.",
			},
			100: {
				h: "100 % Brillentyp!",
				t: "Du bist der Brillentyp, deine Augen fühlen sich wohl und du findest es toll eine Brille zu tragen. Kontaktlinsen sind einfach nichts für dich!"
			}
		},
		"max": 0,
		"min": 0,
		"container": {},
		"qCount": 0,
		"q": {},
		"setup": function (container) {
			console.log("setup");
			Tool.container = $(container);
			Tool.container.find(".start").addClass("hidden");
			Tool.container.find(".questions").removeClass("hidden");
			Tool.qCount = $(container).find(".question").length;
			Tool.container.find(".question").each(function (i) {
				var sort = [];
				$(this).find(".answer input").each(function () {
					sort.push(Number.parseInt($(this).val()));
				});
				Tool.max += Number.parseInt(sort.sort(function (a, b) {
					return a - b;
				}).pop());
				Tool.q[i] = {
					"e": $(this)
				};
				var $q = $(this).attr("data-id", i);
				if (i === 0) {
					Tool.q[i].e.closest(".question-wrapper").removeClass("hidden");
				}
				if (i === Tool.qCount - 1) {
					$q.find(".nav .next").hide();
				} else if (i === 0) {
					$q.find(".nav .prev").hide();
				}
				$q.addClass("q" + i);
				$q.find(".answer").each(function (i) {
					//				var $a = $(this).data("id", i);
					var $a = $(this).attr("data-id", i);
					$a.addClass("a" + i);
					$a.find("input").each(function () {
						$(this).attr({
							"id": "i" + $q.data("id") + $a.data("id"),
							"name": "a" + $q.data("id"),
						});
					});
					$a.find("label").each(function () {
						$(this).attr({
							"for": "i" + $q.data("id") + $a.data("id"),
						});
					});
				});
			});
			console.log(Tool.max);
			Tool.container.on("click", ".next", function () {
				Tool.next($(this));
			});
			Tool.container.on("click", ".prev", function () {
				Tool.prev($(this));
			});
			Tool.container.on("click", ".end", function () {
				Tool.finish();
			});
			Tool.container.on("click", ".again", function () {
				Tool.container.find(".answer input").each(function () {
					this.checked = false;
				});
				Tool.container.find(".finish").addClass("hidden");
				Tool.container.find(".questions").removeClass("hidden");
				Tool.container.find(".questions .question-wrapper:first").removeClass("hidden");
			});
		},
		"calculate": function () {
			var sum = 0;
			Tool.container.find(".answer input:checked").each(function () {
				sum += Number.parseInt($(this).val());
			});
			return sum;
		},
		"finish": function () {
			var result = Tool.calculate();
			var l = result / Tool.max * 100;
			var index = 0
			console.log(Tool.max, result, l);
			Tool.container.find(".tile").css({
				left: l + "%"
			});
			Tool.container.find(".tile .result").text(result);
			Tool.container.find(".questions").addClass("hidden");
			Tool.container.find(".question-wrapper").addClass("hidden");
			if (result < 10) {
				index = 0;
			} else if (result < 20) {
				index = 10;
			} else if (result < 30) {
				index = 20;
			} else if (result < 40) {
				index = 30;
			} else if (result < 50) {
				index = 40;
			} else if (result < 60) {
				index = 50;
			} else if (result < 70) {
				index = 60;
			} else if (result < 80) {
				index = 70;
			} else if (result < 90) {
				index = 80;
			} else if (result < 100) {
				index = 90;
			} else {
				index = 100;
			}
			Tool.container.find(".finish h3.type").html(Tool.result[index].h);
			Tool.container.find(".finish p.type").html(Tool.result[index].t);
			Tool.container.find(".finish").removeClass("hidden");
			console.log("FINISH")
		},
		"next": function ($ele) {
			if (Tool.hasNext($ele)) {
				var id = $ele.closest(".question").data("id") + 1;
				$ele.closest(".question-wrapper").addClass("hidden");
				Tool.q[id].e.closest(".question-wrapper").removeClass("hidden");
			}

		},
		"hasNext": function ($ele) {
			return $ele.closest(".question").data("id") + 1 < Tool.qCount;
		},
		"prev": function ($ele) {
			if (Tool.hasPrev($ele)) {
				var id = $ele.closest(".question").data("id") - 1;
				$ele.closest(".question-wrapper").addClass("hidden");
				Tool.q[id].e.closest(".question-wrapper").removeClass("hidden");
			}
		},
		"hasPrev": function ($ele) {
			return $ele.closest(".question").data("id") > 0;
		},
	};

	$(".run").on("click", function () {
		Tool.init($("#kl-app"));
	});
});